$('.main-menu-toggle').click(function() {
    $('.main-menu').toggleClass('is-open');
    $(this).toggleClass('is-open');
});

(function() {
    var $hpSlider = $('.hp-slideshow');

    if(!$hpSlider.length) {
        return;
    }

    $hpSlider.slick({
        fade: true,
        arrows: false,
        accessibility: false,
        autoplay: true,
        autoplaySpeed: 5000,
        draggable: false,
        pauseOnHover: false,
        speed: 1000
    });
})();

(function() {
    var $hpFooter = $('.hp-footer'),
        $win = $(window),
        prevScroll = 0,
        $scrorrow = $('.hp-scrorrow');

    if(!$hpFooter.length) {
        return;
    }

    $win.on('load scroll', function() {
        var scrollTop = $win.scrollTop();
        if(scrollTop > 10 && prevScroll <= 10) {
            $hpFooter.fadeOut(400);
        } else if (scrollTop <= 10 && prevScroll > 10) {
            $hpFooter.fadeIn(400);
        }
        prevScroll = scrollTop;
    });

    $scrorrow.click(function() {
        $('html, body').animate({
            scrollTop: $win.innerHeight()
        }, 400);
    });
})();

(function() {
    var $galMain = $('.gal-main'),
        $galNav = $('.gal-nav'),
        $galToggle = $('.gal-sidebar-toggle'),
        $win = $(window);

    function mainResize() {
        $galMain.slick('setPosition');
    }

    if(!$galMain.length) {
        return;
    }

    $galMain.slick({
        fade: true,
        asNavFor: '.gal-nav',
        speed: 1000,
        accessibility: false
    });

    $galNav.slick({
        vertical: true,
        slidesToShow: 4,
        focusOnSelect: true,
        asNavFor: '.gal-main',
        swipe: false,
        accessibility: false,
        speed: 1000
    });

    $win.on('load resize', function() {
        var slick = $galNav.slick('getSlick'),
            slideHeight = slick.$slides.filter('.slick-active').eq(0).height(),
            height = $win.innerHeight(),
            topOffset = $galNav.offset().top,
            slidesToShow = Math.max(Math.floor((height - topOffset - 100) / slideHeight), 1);

        $galNav.slick('slickSetOption', 'slidesToShow', slidesToShow, true);
    });

    $galToggle.click(function() {
        $('.subpage-gallery').toggleClass('is-sidebar-open');
        setTimeout(mainResize, 620);
    });

    $galMain.find('.gal-zoom').fancybox({
        prevEffect: 'fade',
        nextEffect: 'fade',
        prevSpeed: 500,
        nextSpeed: 500,
        helpers: {
            thumbs: {
                width: 150,
                height: 100
            }
        }
    });
})();
